import React, { useEffect } from "react";
import window from "global/window";

function ServiceProviders() {
  useEffect(() => {
    window.location.href = "https://classdojo.zendesk.com/hc/en-us/articles/203732189";
  });
  return <></>;
}

export default ServiceProviders;
